

.cvContainer {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 500px
}

.appContainer {
  padding-top:  30px;
  margin: auto;
  max-width: 960px;
}

.sectionIcon {
  font-size: 20px;
  margin-right: 0.75em;
  border-radius: 100%;
  background: #2d7788;
  color: white;
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  align-self: center;
}

.sectionIcon svg {
  margin: 0 auto;
}

.sectionTitle {
  margin-top: 1rem;
}

/*.contactListContainer {
   margin-top: 0px; 
}

.contactListItem {

}*/

.sectionContainer {
  margin: 1rem;
}

.contactListItemContent {
  color:hsl(0, 0%, 96%)	;
  margin-left: 10px;
}

.companyTitle {
  display: inline;
}

.companyMeta, .projectMeta {
  padding: 1em;
}

.projectTitle {
  display: inline;
}

.sectionContent {
  padding: 1em;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid hsl(0, 0%, 71%);
  max-width: 500px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
hr {
  margin: 0;
}

body {
  background-color: #f5f5f5;
}


.project-group:not(:first-of-type) {
  margin-top: 30px;
}

@media print {
  .sectionContainer {
    padding: 0px;
    margin: 0px;
  }

  .commonListContainer {
    padding: 0;
    margin: 0;
  }

  .avoidBreakingOnPrint {
    page-break-inside: avoid;
  }

  .branding {
    display: none;
  }
  body {
    background-color: white;
  }
  .appContainer {
    max-width: 100%;
    padding: 0;
  }

  .sectionContainer {
    padding: 0;
  }
}

.branding {
  margin-top: 20px;
  text-align: center;
}

.subtitle {
  font-size: 0.7em;
  font-weight: 500;
  margin: 2px 0 0 20px;
}
.header {
  background: rgba(23,138,157,0.05);
  padding: 15px 20px;
}
.description {
  margin-bottom: 20px;
}
@media print {
  .description {}
  .no-print {
    display: none!important;
  }
}